import React, { useState } from "react";
import AlertComponent from "../components/AlertComponent/AlertComponent";

export function useAlert (alertList) {
	const [ showSuccessAlert, setShowSuccessAlert ] = useState(false);
	const [ showErrorAlert, setShowErrorAlert ] = useState(false);

	const [ successAlertMessage, setSuccessAlertMessage ] = useState("");
	const [ errorAlertMessage, setErrorAlertMessage ] = useState("");

	function onErrorMessageClickHandler () {
		setShowErrorAlert(false);
	}
	function onSuccessMessageClickHandler () {
		setShowSuccessAlert(false);
	}

	const functions = alertList.map((alertParam) => {
		if (alertParam === "success") {
			return function success (alertMessage) {
				setSuccessAlertMessage(alertMessage);
				setShowErrorAlert(false);
				setShowSuccessAlert(true);
			};
		} else if (alertParam === "error") {
			return function error (alertMessage) {
				setErrorAlertMessage(alertMessage);
				setShowSuccessAlert(false);
				setShowErrorAlert(true);
			};
		}
	});
	const alerts = alertList.map((alertParam) => {
		if (alertParam === "success") {
			return (
				<AlertComponent
					style="success"
					hidden={!showSuccessAlert}
					onAlertClickHandler={onSuccessMessageClickHandler}
					key={alertParam}
				>
					{successAlertMessage}
				</AlertComponent>
			);
		} else if (alertParam === "error") {
			return (
				<AlertComponent
					style="danger"
					hidden={!showErrorAlert}
					onAlertClickHandler={onErrorMessageClickHandler}
					key={alertParam}
				>
					{errorAlertMessage}
				</AlertComponent>
			);
		} else {
			return null;
		}
	});
	function Alerts () {
		return alerts;
	}
	return [ ...functions, Alerts ];
}

export default useAlert;
