export const WEEKS_START = new Date(2022, 0, 3);

export const CURRENCIES = {
  FIAT: ["UAH", "USD"],
  CRYPRO: ["BTC", "USDT"],
};

export const REPORTING_DAYS_TYPES = {
  weekdays: "weekdays",
  weekends: "weekends",
};
