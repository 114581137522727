import "./styles/App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Cookies from "universal-cookie";
import LoginPage from "./pages/LoginPage";
import React, { useState } from "react";
import HeaderComponent from "./components/HeaderComponent/HeaderComponent";
import MainPage from "./pages/MainPage";
import OfficePage from "./pages/OfficePage";

import { ContextProvider } from "./context";

function App() {
  const cookies = new Cookies();

  const [user, setUser] = useState(
    cookies.get("user") ? cookies.get("user") : null
  );

  cookies.addChangeListener(function (name, value) {
    console.log(name, value);
    if (name === "user") {
      setUser(value);
    }
  });

  return (
    <ContextProvider>
      <Router>
        {user ? (
          <React.Fragment>
            <HeaderComponent />
            <div className="container">
              <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/office/:officeId" element={<OfficePage />} />
              </Routes>
            </div>
          </React.Fragment>
        ) : (
          <LoginPage />
        )}
      </Router>
    </ContextProvider>
  );
}

export default App;
