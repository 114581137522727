import moment from "moment";

class WeeksList {
	constructor (fromDate) {
		if (this.weeksList && this.weeksList.length) return this.weeksList;

		const dateFormat = "YYYY-MM-DD";
		const dateFrom = moment(fromDate);
		const dateNow = moment().isoWeekday(1);
		this.weeksList = [];
		let currentDate = dateFrom;
		while (dateNow.diff(currentDate, "days") >= 0) {
			this.weeksList.push({
				dateFrom: currentDate.format(dateFormat),
				dateTo: currentDate.add(6, "d").format(dateFormat)
			});
			currentDate = currentDate.add(1, "d");
		}
	}
	getWeeksList () {
		return this.weeksList;
	}
	getByIndex (index) {
		return this.weeksList[index];
	}
	getLastWeek () {
		return this.weeksList[this.weeksList.length - 1];
	}
}

export default WeeksList;
