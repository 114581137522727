import axiosInstance from "../axios";

class AccountingService {
  getOfficesList = async (dateFrom, dateTo, isWeekends = true) => {
    const firstPage = 1;
    const data = [];
    const firstRes = await axiosInstance(
      `/accounting/${isWeekends ? "weekend-" : ""}offices`,
      {
        params: {
          start_date: dateFrom,
          end_date: dateTo,
          page: 1,
        },
      }
    );

    if (!firstRes.data.status) {
      return firstRes.data;
    }

    const lastPage = firstRes.data.data.last_page;
    data.push(...firstRes.data.data.data);
    const total_statistics = firstRes?.data?.total_statistics;
    for (let i = firstPage + 1; i <= lastPage; i++) {
      const res = await axiosInstance(
        `/accounting/${isWeekends ? "weekend-" : ""}`,
        {
          params: {
            start_date: dateFrom,
            end_date: dateTo,
            page: i,
          },
        }
      );
      if (!res.data.status) {
        return res.data;
      }
      data.push(...res.data.data.data);
    }

    return { status: true, data, total_statistics };
  };

  getOfficesListForDownloading = async (dateFrom, dateTo, isWeekends = true) =>
    await axiosInstance(`/accounting/${isWeekends ? "weekend-" : ""}offices`, {
      params: {
        start_date: dateFrom,
        end_date: dateTo,
        page: 1,
        action: "export",
      },
      responseType: "blob",
    }).then((response) => {
      // NOTE! Pattern from stackoverflow.com
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        `${
          isWeekends ? "Субботний_отчет_" : "Недельный_отчет_"
        }${dateFrom}_${dateTo}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });

  getOfficeIncomes = async (officeId, dateFrom, dateTo) => {
    const firstPage = 1;
    const data = [];
    let statistics = {};
    const firstRes = await axiosInstance(
      `/accounting/${officeId}/incomes?start_date=${dateFrom}&end_date=${dateTo}&page=${firstPage}`
    );
    if (!firstRes.data.status) return firstRes.data;
    const lastPage = firstRes.data.data.last_page;
    data.push(...firstRes.data.data.data);
    statistics = firstRes.data.statistics;
    for (let i = firstPage + 1; i <= lastPage; i++) {
      const res = await axiosInstance(
        `/accounting/${officeId}/incomes?start_date=${dateFrom}&end_date=${dateTo}&page=${i}`
      );
      if (!res.data.status) return res.data;
      data.push(...res.data.data.data);
    }
    return { status: true, data, statistics };
  };
  getOfficeExpenses = async (officeId, dateFrom, dateTo) => {
    const firstPage = 1;
    const data = [];
    let statistics = {};

    const firstRes = await axiosInstance(
      `/accounting/${officeId}/expenses?start_date=${dateFrom}&end_date=${dateTo}&page=${firstPage}`
    );
    if (!firstRes.data.status) return firstRes.data;
    const lastPage = firstRes.data.data.last_page;
    data.push(...firstRes.data.data.data);
    statistics = firstRes.data.statistics;

    for (let i = firstPage + 1; i <= lastPage; i++) {
      const res = await axiosInstance(
        `/accounting/${officeId}/expenses?start_date=${dateFrom}&end_date=${dateTo}&page=${i}`
      );
      if (!res.data.status) return res.data;
      data.push(...res.data.data.data);
    }
    return { status: true, data, statistics };
  };

  getOfficeWeeklyReport = (officeId, dateFrom, dateTo) =>
    axiosInstance(
      `/accounting/${officeId}/weekly-report?start_date=${dateFrom}&end_date=${dateTo}`
    );

  getOfficePayouts = async (officeId, dateFrom, dateTo) => {
    const firstPage = 1;
    const data = [];
    let statistics = {};
    const firstRes = await axiosInstance(
      `/accounting/${officeId}/payouts?start_date=${dateFrom}&end_date=${dateTo}&page=${firstPage}`
    );
    if (!firstRes.data.status) return firstRes.data;
    const lastPage = firstRes.data.data.last_page;
    data.push(...firstRes.data.data.data);
    statistics = firstRes.data.statistics;
    for (let i = firstPage + 1; i <= lastPage; i++) {
      const res = await axiosInstance(
        `/accounting/${officeId}/payouts?start_date=${dateFrom}&end_date=${dateTo}&page=${i}`
      );
      if (!res.data.status) return res.data;
      data.push(...res.data.data.data);
    }
    return { status: true, data, statistics };
  };
  getOfficeData = (officeId) => axiosInstance.get(`/settings/${officeId}/get`);
}

export default AccountingService;
