import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment";

import OfficeExpensesComponent from "../components/OfficeExpensesComponent/OfficeExpensesComponent";
import OfficeIncomesComponent from "../components/OfficeIncomesComponent/OfficeIncomesComponent";
import OfficeWeeklyReportComponent from "../components/OfficeWeeklyReportComponent/OfficeWeeklyReportComponent";
import WeekSelectorComponent from "../components/WeekSelectorComponent/WeekSelectorComponent";

import WeeksList from "../helpers/WeekList";
import { WEEKS_START, REPORTING_DAYS_TYPES } from "../helpers/constants";
import AccountingService from "../services/AccountingService";
import { useMyContext } from "../context";

function OfficePage() {
  const weeksList = new WeeksList(WEEKS_START);

  const { selectedWeekIndex } = useMyContext();
  const { officeId } = useParams();
  const accountingService = new AccountingService();

  const [selectedWeek, setSelectedWeek] = useState(null);
  const [officeName, setOfficeState] = useState("");
  const [balance, setBalanse] = useState(null);
  const [currentReportingDaysTypes, setCurrentReportingDaysTypes] = useState(
    REPORTING_DAYS_TYPES?.weekdays
  );

  useEffect(() => {
    (async function () {
      const res = await accountingService.getOfficeData(officeId);
      if (res.data.status) {
        setOfficeState(res.data.data.name);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBalance(selectedWeek);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek]);

  useEffect(() => {
    const newWeekPeriod = weeksList.getByIndex(selectedWeekIndex);
    if (currentReportingDaysTypes === REPORTING_DAYS_TYPES?.weekdays) {
      const newDate = new Date(newWeekPeriod?.dateFrom);
      newDate.setDate(newDate.getDate() + 4);
      newWeekPeriod.dateTo = moment(newDate).format("YYYY-MM-DD");
    }
    if (currentReportingDaysTypes === REPORTING_DAYS_TYPES?.weekends) {
      const newDate = new Date(newWeekPeriod?.dateTo);
      newDate.setDate(newDate.getDate() - 1);
      newWeekPeriod.dateFrom = moment(newDate).format("YYYY-MM-DD");
    }

    setSelectedWeek(newWeekPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReportingDaysTypes, selectedWeekIndex]);

  async function getBalance(selectedWeek) {
    if (!selectedWeek) {
      return;
    }
    const reportRes = await accountingService.getOfficeWeeklyReport(
      officeId,
      ...Object.values(selectedWeek)
    );
    if (reportRes.data.status) {
      setBalanse(reportRes.data.data);
    }
  }

  return (
    <React.Fragment>
      <div className="card my-3 position-relative">
        <div className="card-header p-3 text-center">
          <h2>{officeName}</h2>
        </div>
      </div>
      <div className="card">
        <div className="card-header p-3 pb-0">
          <WeekSelectorComponent>
            {!!officeName ? (
              <div className="balance-conteiner text-danger text-end fs-7">
                Остаток крипты: &nbsp;
                {(+balance?.balance_history_in_btc || 0).toFixed(4)} BTC; &nbsp;
                {(+balance?.balance_history_in_usdt || 0).toFixed(2)} USDT
              </div>
            ) : (
              ""
            )}
          </WeekSelectorComponent>
        </div>
        <div className="card-body">
          <Tabs
            defaultActiveKey={REPORTING_DAYS_TYPES?.weekdays}
            onSelect={setCurrentReportingDaysTypes}
          >
            <Tab
              eventKey={REPORTING_DAYS_TYPES?.weekdays}
              title="Недельный отчет"
            >
              <Tabs>
                <Tab eventKey="income" title="Приход">
                  <OfficeIncomesComponent
                    selectedWeek={selectedWeek}
                    shouldToRefetch={
                      REPORTING_DAYS_TYPES?.weekdays ===
                      currentReportingDaysTypes
                    }
                  />
                </Tab>
                <Tab eventKey="expense" title="Расход">
                  <OfficeExpensesComponent
                    selectedWeek={selectedWeek}
                    shouldToRefetch={
                      REPORTING_DAYS_TYPES?.weekdays ===
                      currentReportingDaysTypes
                    }
                  />
                </Tab>
                <Tab eventKey="weekly_report" title="Недельный отчёт">
                  <OfficeWeeklyReportComponent
                    selectedWeek={selectedWeek}
                    officeWeeklyReport={balance}
                    shouldToRefetch={
                      REPORTING_DAYS_TYPES?.weekdays ===
                      currentReportingDaysTypes
                    }
                    isShownExpenses
                  />
                </Tab>
              </Tabs>
            </Tab>
            <Tab
              eventKey={REPORTING_DAYS_TYPES?.weekends}
              title="Субботний отчет"
            >
              <Tabs>
                <Tab eventKey="income" title="Приход">
                  <OfficeIncomesComponent
                    selectedWeek={selectedWeek}
                    shouldToRefetch={
                      REPORTING_DAYS_TYPES?.weekends ===
                      currentReportingDaysTypes
                    }
                  />
                </Tab>
                <Tab eventKey="expense" title="Расход">
                  <OfficeExpensesComponent
                    selectedWeek={selectedWeek}
                    shouldToRefetch={
                      REPORTING_DAYS_TYPES?.weekends ===
                      currentReportingDaysTypes
                    }
                  />
                </Tab>
                <Tab eventKey="weekly_report" title="Недельный отчёт">
                  <OfficeWeeklyReportComponent
                    selectedWeek={selectedWeek}
                    officeWeeklyReport={balance}
                    shouldToRefetch={
                      REPORTING_DAYS_TYPES?.weekends ===
                      currentReportingDaysTypes
                    }
                  />
                </Tab>
              </Tabs>
            </Tab>
          </Tabs>
        </div>
      </div>
    </React.Fragment>
  );
}

export default OfficePage;
