import { Link } from "react-router-dom";

function HeaderComponent () {
	return (
		<header className="bg-dark p-3">
			<div className="d-flex">
				<div className="admin-logo">ADMIN</div>
				<div className="mx-3 d-flex align-items-end mx-3">
					<Link to="/" className="pb-1">
						Список офисов
					</Link>
				</div>
			</div>
		</header>
	);
}

export default HeaderComponent;
