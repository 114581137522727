import { useState } from "react";
import Cookies from "universal-cookie";
import useAlert from "../hooks/useAlert";
import AuthService from "../services/AuthService";

const cookies = new Cookies();

function LoginPage () {
	const authService = new AuthService();

	const [ error, Alerts ] = useAlert([ "error" ]);
	const [ login, setLogin ] = useState("");
	const [ password, setPassword ] = useState("");

	async function onLoginButtonClickHandeler () {
		try {
			const loginRes = await authService.login(login, password);
			if (loginRes.data.access_token) {
				cookies.set("token", "Bearer " + loginRes.data.access_token, { path: "/" });
				cookies.set("token_expires_in", loginRes.data.expires_in, { path: "/" });
				const userRes = await authService.getUserInformation();
				if (userRes.data.email) {
					cookies.set("user", userRes.data, { path: "/" });
					window.location.reload();
				}
			}
		} catch (e) {
			console.log(e);
			error("Пароль или логин неправильный.");
		}
	}
	function onFormSubmitHandler (e) {
		e.preventDefault();
	}
	return (
		<div className="container" style={{ height: "100vh" }}>
			<div className="d-flex align-items-center justify-content-around" style={{ height: "100vh" }}>
				<div className="card m-auto" style={{ maxWidth: "600px" }}>
					<div className="card-header">
						<div className="text-center">
							<h5>Авторизация</h5>
							<form onSubmit={onFormSubmitHandler}>
								<input
									type="text"
									className="form-control mb-2"
									placeholder="Логин"
									name="login"
									value={login}
									onChange={(e) => setLogin(e.target.value)}
								/>
								<input
									type="password"
									className="form-control mb-2"
									placeholder="Пароль"
									name="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
								/>
								<button className="btn btn-info" onClick={onLoginButtonClickHandeler}>
									Войти
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
			<Alerts />
		</div>
	);
}

export default LoginPage;
