import React, { createContext, useState, useContext } from "react";

import { WEEKS_START } from "./helpers/constants";
import WeeksList from "./helpers/WeekList";

const Context = createContext();

export function ContextProvider({ children }) {
  const weeksList = new WeeksList(WEEKS_START);

  const [selectedWeekIndex, setSelectedWeekIndex] = useState(
    weeksList.getWeeksList().length - 1
  );

  return (
    <Context.Provider value={{ selectedWeekIndex, setSelectedWeekIndex }}>
      {children}
    </Context.Provider>
  );
}

export function useMyContext() {
  return useContext(Context);
}
