import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AccountingService from "../../services/AccountingService";
import { getDayOfWeek } from "../../helpers/methods";

function OfficeIncomesComponent({ selectedWeek, shouldToRefetch }) {
  const { officeId } = useParams();
  const accountingService = new AccountingService();
  const [tableData, setTableData] = useState([]);
  const [totalStatistic, setTotalStatistic] = useState(null);

  useEffect(() => {
    if (selectedWeek && shouldToRefetch) {
      (async function () {
        const res = await accountingService.getOfficeIncomes(
          officeId,
          ...Object.values(selectedWeek)
        );
        if (res.status) {
          const managers = res.data;
          const groupsInObject = managers.reduce((group, el) => {
            if (el.manager_role === "manager") {
              group[el.group_id] = group[el.group_id] || [];
              group[el.group_id].push(el);
            }
            return group;
          }, {});
          const ManagersByGroup = [];
          for (var key in groupsInObject) {
            ManagersByGroup.push(groupsInObject[key]);
          }
          setTableData(ManagersByGroup);
          setTotalStatistic(res.statistics);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek]);

  return (
    <div className="office-incomes-container">
      <table className="table mt-2">
        <thead>
          <tr className="table-secondary">
            <th>#</th>
            <th>Дата</th>
            <th>Комментарий</th>
            <th>Менеджеры</th>
            <th>Сумма в $</th>
            <th>Выплата</th>
            <th>%</th>
            <th>Зарплата</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr
              key={row[0]?.group_id}
              className={
                getDayOfWeek(row[0]?.date) === "СБ" ||
                getDayOfWeek(row[0]?.date) === "ВС"
                  ? "weekend"
                  : ""
              }
            >
              <td>{index + 1}</td>
              <td>{row[0]?.date + " " + getDayOfWeek(row[0]?.date)}</td>
              <td>{row[0]?.comment}</td>
              <td>
                {row
                  .map((el) => {
                    return el?.manager_name;
                  })
                  .join("; ")}
              </td>
              <td>
                $ {(row[0]?.total_amount * +row[0]?.fixed_rate).toFixed(2)}
              </td>
              <td>
                {row[0].currency.toUpperCase() === "USD" && "$"}
                &nbsp;
                {row[0]?.currency.toUpperCase() === "BTC"
                  ? (+row[0]?.total_amount).toFixed(4)
                  : (+row[0]?.total_amount).toFixed(2)}
                &nbsp;
                {row[0]?.currency.toUpperCase() !== "USD" && row[0]?.currency}
              </td>
              <td>{row[0]?.total_percent}%</td>
              <td>
                ${" "}
                {row
                  .reduce((red, el) => {
                    return red + +el?.manager_salary_in_usd;
                  }, 0)
                  .toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pivot-table p-3">
        <div className="mb-3">
          <div>
            <b>
              {" "}
              Выплатили за неделю: ${" "}
              {totalStatistic &&
                (+totalStatistic.payments_for_the_week).toFixed(2)}
            </b>
          </div>
          <div>
            <b> Всего BTC: </b>{" "}
            {(totalStatistic ? +totalStatistic.total_btc : 0).toFixed(4)}
          </div>
          <div>
            <b> Всего USDT: </b>{" "}
            {(totalStatistic ? +totalStatistic.total_usdt : 0).toFixed(2)}
          </div>
        </div>
        <div>
          <div>
            <b>
              Общий зарплатный фонд: ${" "}
              {(totalStatistic ? +totalStatistic.total_salaries : 0).toFixed(2)}
            </b>
          </div>
          <div>
            Клоузер: ${" "}
            {(totalStatistic ? +totalStatistic.clouser_salary : 0).toFixed(2)}
          </div>
          <div>
            Менеджеры: ${" "}
            {(totalStatistic ? +totalStatistic.manager_salaries : 0).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfficeIncomesComponent;
