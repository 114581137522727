import Cookies from "universal-cookie";
global.Buffer = global.Buffer || require("buffer/").Buffer;

const axios = require("axios");

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
});
const cookies = new Cookies();

//Resfresh auth token interceptor
// axiosInstance.interceptors.response.use(async function (response) {
// 	const token = cookies.get("token");
// 	if (!response.data.status && response.data.error === "Unauthenticated.") {
// 		const res = await axios.post(
// 			`${process.env.REACT_APP_API_BASE_URL}auth/refresh`,
// 			{},
// 			{
// 				headers: {
// 					Authorization: token
// 				}
// 			}
// 		);
// 		console.log(res);
// 		if (res.data.access_token) {
// 			const newToken = `Bearer ${res.data.access_token}`;
// 			cookies.set("token", newToken, { path: "/" });
// 			response.config.headers.Authorization = newToken;

// 			const newResponse = axios(response.config);
// 			return newResponse;
// 		} else {
// 			console.log("Failed refresh!!");
// 			console.log(res);
// 		}
// 	}
// 	return response;
// });

let lastRefresh = 0;
// NOTE! The interceptor refreshes token if about to expire
axiosInstance.interceptors.response.use(async function (response) {
  const token = response.config.headers.Authorization;
  if (!token) return response;
  const buff = Buffer.from(token.split(".")[1], "base64");
  const tokenCreationStamp = JSON.parse(buff.toString()).nbf * 1000;
  const tokenExpiresIn = cookies.get("token_expires_in") * 1000;

  const now = Date.now();
  if (now - lastRefresh < tokenExpiresIn / 2) return response;
  const timeLeft = tokenExpiresIn - (now - tokenCreationStamp);

  if (timeLeft < 0) {
    cookies.remove("token", { path: "/" });
    cookies.remove("user", { path: "/" });
    cookies.remove("token_expires_in", { path: "/" });

    window.location.reload();
  } else if (timeLeft < tokenExpiresIn / 4) {
    lastRefresh = now;
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}auth/refresh`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    if (res.data.access_token) {
      const newToken = `Bearer ${res.data.access_token}`;
      cookies.set("token", newToken, { path: "/" });
      console.log("Token refreshed!");
    } else {
      console.log("Failed refresh!!");
    }
  }
  return response;
});
// This interceptor adds authorization token to requests
axiosInstance.interceptors.request.use(function (request) {
  const token = cookies.get("token");
  request.headers.common.Authorization = token;
  return request;
});

export default axiosInstance;
