import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import moment from "moment";

import WeekSelectorComponent from "../components/WeekSelectorComponent/WeekSelectorComponent";
import WeeklyCommonComponent from "../components/WeeklyCommonComponent/WeeklyCommonComponent";

import AccountingService from "../services/AccountingService";
import { REPORTING_DAYS_TYPES, WEEKS_START } from "../helpers/constants";
import WeeksList from "../helpers/WeekList";
import { useMyContext } from "../context";

function MainPage() {
  const accountingService = new AccountingService();
  const weeksList = new WeeksList(WEEKS_START);

  const { selectedWeekIndex } = useMyContext();

  const [selectedWeek, setSelectedWeek] = useState(null);

  const [weekdaysTableData, setWeekdaysTableData] = useState([]);
  const [weekdaysTotal, setWeekdaysTotal] = useState(undefined);
  const [weekendsTableData, setWeekendsTableData] = useState([]);
  const [weekendsTotal, setWeekendsTotal] = useState(undefined);

  const [currentReportingDaysTypes, setCurrentReportingDaysTypes] = useState(
    REPORTING_DAYS_TYPES?.weekdays
  );

  useEffect(() => {
    onWeekChange(selectedWeek);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek]);

  useEffect(() => {
    const newWeekPeriod = weeksList.getByIndex(selectedWeekIndex);
    if (currentReportingDaysTypes === REPORTING_DAYS_TYPES?.weekdays) {
      const newDate = new Date(newWeekPeriod?.dateFrom);
      newDate.setDate(newDate.getDate() + 4);
      newWeekPeriod.dateTo = moment(newDate).format("YYYY-MM-DD");
    }
    if (currentReportingDaysTypes === REPORTING_DAYS_TYPES?.weekends) {
      const newDate = new Date(newWeekPeriod?.dateTo);
      newDate.setDate(newDate.getDate() - 1);
      newWeekPeriod.dateFrom = moment(newDate).format("YYYY-MM-DD");
    }

    setSelectedWeek(newWeekPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReportingDaysTypes, selectedWeekIndex]);

  async function onWeekChange(selectedWeek) {
    const res = await accountingService.getOfficesList(
      ...Object.values(selectedWeek),
      currentReportingDaysTypes === REPORTING_DAYS_TYPES.weekends
    );

    if (res.status) {
      if (currentReportingDaysTypes === REPORTING_DAYS_TYPES.weekends) {
        setWeekendsTableData(res?.data);
        setWeekendsTotal(res?.total_statistics);
      } else {
        setWeekdaysTableData(res.data);
        setWeekdaysTotal(res?.total_statistics);
      }
    }
  }

  async function handleDownloadTableData(selectedWeek) {
    accountingService.getOfficesListForDownloading(
      ...Object.values(selectedWeek),
      currentReportingDaysTypes === REPORTING_DAYS_TYPES.weekends
    );
  }

  return (
    <div className="card">
      <div className="card-header p-3">
        <WeekSelectorComponent onWeekChange={onWeekChange} />
      </div>
      <Tabs
        defaultActiveKey={REPORTING_DAYS_TYPES?.weekdays}
        onSelect={setCurrentReportingDaysTypes}
      >
        <Tab
          eventKey={REPORTING_DAYS_TYPES?.weekdays}
          currentReportingDaysTypes={currentReportingDaysTypes}
          title="Недельный отчет"
        >
          <WeeklyCommonComponent
            tableData={weekdaysTableData}
            totalData={weekdaysTotal}
            handleDownloadTableData={() =>
              handleDownloadTableData(selectedWeek)
            }
          />
        </Tab>
        <Tab
          eventKey={REPORTING_DAYS_TYPES?.weekends}
          currentReportingDaysTypes={currentReportingDaysTypes}
          title="Субботний отчет"
        >
          <WeeklyCommonComponent
            tableData={weekendsTableData}
            totalData={weekendsTotal}
            handleDownloadTableData={() =>
              handleDownloadTableData(selectedWeek)
            }
          />
        </Tab>
      </Tabs>
    </div>
  );
}

export default MainPage;
