import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function WeeklyCommonComponent({
  tableData,
  totalData,
  handleDownloadTableData,
}) {
  const [shownTableData, setShownTableData] = useState([]);
  const [searchRequest, setSearchRequest] = useState("");

  useEffect(() => {
    setShownTableData(tableData);
    setSearchRequest("");
  }, [tableData]);

  function onSearchInputChangeHandler(e) {
    const value = e.target.value;
    setSearchRequest(value);

    const filteredData = tableData.filter((row) =>
      row.name.toLowerCase().includes(value.toLowerCase())
    );
    setShownTableData(filteredData);
  }

  function getRowLayout(row) {
    return (
      <>
        <td>{row?.total_incomes?.toFixed(2)}$</td>
        <td>{row?.total_payouts?.toFixed(2)}$</td>
        <td>{row?.total_salaries?.toFixed(2)}$</td>
        <td>{row?.total_expenses?.toFixed(2)}$</td>
        <td>{row?.total_expenses_plus_salaries?.toFixed(2)}$</td>
        <td>{row?.accounting_income?.toFixed(2)}$</td>
        <td>≈ {row?.balance_history?.toFixed(2)}$</td>
      </>
    );
  }

  return (
    <div className="card-body">
      <div className="d-flex justify-content-center position-relative">
        <input
          className="form-control w-25"
          placeholder="Поиск"
          value={searchRequest}
          onChange={onSearchInputChangeHandler}
        />
        <button
          className="btn btn-success position-absolute top-0 end-0"
          onClick={handleDownloadTableData}
        >
          Скачать в Excel
        </button>
      </div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Офис</th>
            <th>Приход</th>
            <th>Вывод</th>
            <th>Зарплата</th>
            <th>Расход нал</th>
            <th>Расход + ЗП</th>
            <th>Прибыль</th>
            <th>Остаток BTC</th>
          </tr>
        </thead>
        <tbody>
          {shownTableData.map((row, index) => (
            <tr
              key={row?.id}
              className={
                row.statistics.accounting_income > 0
                  ? "table-success"
                  : "table-danger"
              }
            >
              <td>{index + 1}</td>
              <td>
                <Link to={`/office/${row.id}`}>
                  {row.name ? row.name : "Нет данных"}
                </Link>
              </td>
              {getRowLayout(row.statistics)}
            </tr>
          ))}
          <tr className="fw-bold">
            <td>-</td>
            <td>Всего</td>
            {getRowLayout(totalData)}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
