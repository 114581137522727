import axiosInstance from "../axios";

class AuthService {
	login = (username, password) => {
		const data = new URLSearchParams();
		data.append("email", username);
		data.append("password", password);

		return axiosInstance.post(`/auth/login`, data);
	};

	logout = () => axiosInstance.post("/auth/logout", {});

	getUserInformation = () => axiosInstance.post("/auth/me", {});
}

export default AuthService;
