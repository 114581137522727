import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CURRENCIES } from "../../helpers/constants";
import AccountingService from "../../services/AccountingService";

function OfficeExpensesComponent({ selectedWeek, shouldToRefetch }) {
  const { officeId } = useParams();
  const accountingService = new AccountingService();
  const [fiatTableData, setFiatTableData] = useState([]);
  const [cryptoTableData, setCryptoTableData] = useState([]);

  const [fiatTotal, setFiatTotal] = useState({});
  const [cryptoTotal, setCryptoTotal] = useState({});

  useEffect(() => {
    if (selectedWeek && shouldToRefetch) {
      (async function () {
        const res = await accountingService.getOfficeExpenses(
          officeId,
          ...Object.values(selectedWeek)
        );
        if (res.status) {
          setFiatTableData(
            res.data.filter((row) => CURRENCIES.FIAT.includes(row.currency))
          );
          setCryptoTableData(
            res.data.filter((row) => CURRENCIES.CRYPRO.includes(row.currency))
          );
          setFiatTotal(
            CURRENCIES.FIAT.reduce((acc, fiat) => {
              const fiat_LC = fiat.toLocaleLowerCase();
              acc[fiat_LC] = res.statistics[fiat_LC]
                ? +res.statistics[fiat_LC]
                : 0;
              return acc;
            }, {})
          );
          setCryptoTotal(
            CURRENCIES.CRYPRO.reduce((acc, crypto) => {
              const crypto_LC = crypto.toLocaleLowerCase();
              acc[crypto_LC] = res.statistics[crypto_LC]
                ? +res.statistics[crypto_LC]
                : 0;
              return acc;
            }, {})
          );
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek]);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-heading text-center">Наличные</div>
        <div className="card-body">
          <table className="table table-hover">
            <thead>
              <tr className="table-secondary">
                <th>#</th>
                <th>Дата</th>
                <th>Комментарий</th>
                <th>Сумма</th>
                <th>Сумма в $</th>
              </tr>
            </thead>
            <tbody>
              {fiatTableData.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.date.split(" ")[0]}</td>
                  <td>{row.name}</td>
                  <td>
                    {row?.currency.toUpperCase() === "USD" && "$ "}
                    {(+row?.amount ? +row?.amount : 0).toFixed(2)}
                    {row?.currency.toUpperCase() !== "USD" &&
                      " " + row?.currency}
                  </td>
                  <td>
                    ${" "}
                    {(+row?.amount_in_usd ? +row?.amount_in_usd : 0).toFixed(2)}
                  </td>
                </tr>
              ))}
              <tr className="table-success">
                <td>Total</td>
                <td />
                <td />
                <td>
                  {Object.entries(fiatTotal).map(([key, value]) => (
                    <div key={key}>
                      {key.toUpperCase() === "USD" && "$ "}
                      {(+value).toFixed(2)}
                      {key.toUpperCase() !== "USD" && " " + key}
                    </div>
                  ))}
                </td>
                <td>
                  ${" "}
                  {fiatTableData
                    .reduce((acc, item) => acc + +item.amount_in_usd, 0)
                    .toFixed(2)}
                </td>
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="card">
        <div className="card-heading text-center">Крипто</div>
        <div className="card-body">
          <table className="table table-hover">
            <thead>
              <tr className="table-secondary">
                <th>#</th>
                <th>Дата</th>
                <th>Комментарий</th>
                <th>Сумма</th>
                <th>Сумма в $</th>
              </tr>
            </thead>
            <tbody>
              {cryptoTableData.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row?.date}</td>
                  <td>{row?.name}</td>
                  <td>
                    {row?.currency.toUpperCase() === "BTC"
                      ? (row?.amount ? +row?.amount : 0).toFixed(4)
                      : (row?.amount ? +row?.amount : 0).toFixed(2)}
                    &nbsp;{row?.currency}
                  </td>
                  <td>{(+row?.amount_in_usd).toFixed(2)}</td>
                </tr>
              ))}
              <tr className="table-success">
                <td>Total</td>
                <td />
                <td />
                <td>
                  {Object.entries(cryptoTotal).map(([key, value]) => (
                    <div key={key}>
                      {key.toUpperCase() === "BTC"
                        ? (+value).toFixed(4)
                        : (+value).toFixed(2)}
                      &nbsp;{key}
                    </div>
                  ))}
                </td>
                <td>
                  {cryptoTableData
                    .reduce((acc, item) => acc + +item.amount_in_usd, 0)
                    .toFixed(2)}
                </td>
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}

export default OfficeExpensesComponent;
