import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AccountingService from "../../services/AccountingService";

function OfficeWeeklyReportComponent({
  selectedWeek,
  officeWeeklyReport,
  shouldToRefetch,
  isShownExpenses,
}) {
  const { officeId } = useParams();
  const accountingService = new AccountingService();

  const [payoutsData, setPayoutsData] = useState([]);
  const [payoutsTotal, setPayoutsTotal] = useState({});

  useEffect(() => {
    if (selectedWeek && shouldToRefetch) {
      (async function () {
        const payoutsRes = await accountingService.getOfficePayouts(
          officeId,
          ...Object.values(selectedWeek)
        );
        if (payoutsRes.status) {
          setPayoutsData(payoutsRes.data);
          setPayoutsTotal(payoutsRes.statistics);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek]);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-heading text-center">Недельный отчёт</div>
        <div className="card-body">
          <table className="table table-hover">
            <thead>
              <tr className="table-secondary">
                <th>Приход $</th>
                <th>Вывод $</th>
                <th>Зарплата $</th>
                {isShownExpenses ? (
                  <>
                    <th>Расход нал $</th>
                    <th>Расход + ЗП $</th>
                  </>
                ) : (
                  ""
                )}
                <th>Прибыль $</th>
                <th>Остаток крипто</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{officeWeeklyReport?.total_incomes}</td>
                <td>{officeWeeklyReport?.total_payouts}</td>
                <td>{officeWeeklyReport?.total_salaries}</td>
                {isShownExpenses ? (
                  <>
                    <td>{officeWeeklyReport?.total_expenses}</td>
                    <td>{officeWeeklyReport?.total_expenses_plus_salaries}</td>
                  </>
                ) : (
                  ""
                )}

                <td>{officeWeeklyReport?.accounting_income}</td>
                <td>≈ $ {officeWeeklyReport?.balance_history}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="card">
        <div className="card-heading text-center">Выводы</div>
        <div className="card-body">
          <table className="table table-hover">
            <thead>
              <tr className="table-secondary">
                <th>#</th>
                <th>Дата</th>
                <th>Сумма вывода</th>
                <th>Курс</th>
                <th>Комиссия</th>
              </tr>
            </thead>
            <tbody>
              {payoutsData.map((payout, index) => (
                <tr key={payout?.id}>
                  <td>{index + 1}</td>
                  <td>{payout?.date}</td>
                  <td>
                    <div>
                      {payout?.currency} ={" "}
                      {payout?.currency.toUpperCase() === "BTC"
                        ? (+payout?.amount).toFixed(4)
                        : (+payout?.amount).toFixed(2)}
                    </div>
                    <div>$ {(+payout?.payout_sum?.usdt).toFixed(2)}</div>
                  </td>
                  <td>
                    $ {payout?.currency} = {payout?.exchange_rate}
                  </td>
                  <td>{payout?.percent} %</td>
                </tr>
              ))}
              <tr className="table-success">
                <td>Total</td>
                <td />
                <td>
                  <div>Всего $ {+payoutsTotal?.total}</div>
                  <div>{payoutsTotal?.btc ? +payoutsTotal?.btc : 0} btc</div>
                  <div>{payoutsTotal?.usdt ? +payoutsTotal?.usdt : 0} usdt</div>
                </td>
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}

export default OfficeWeeklyReportComponent;
